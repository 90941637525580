import '@styles/globals.scss'
import 'antd/dist/antd.css';
import Head from "next/head";
import {useEffect} from "react";
import ReactGA from "react-ga4"


function ArtistleApp({Component, pageProps}) {

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            ReactGA.initialize("G-L6502QBV5K");
        }
    }, []);

    return (
        <>
            <Head>
                <meta name="description"
                      content="Guess the artist of the day based on their songs and share your score with your friends. Can you find it before running out of guesses?"/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://artistle.jbonet.xyz/"/>
                <meta property="og:description"
                      content="Guess the artist of the day based on their songs and share your score with your friends. Can you find it before running out of guesses?"/>
                <meta property="og:image" content="https://artistle.jbonet.xyz/images/artistle_og_1200x630.png"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://artistle.jbonet.xyz/"/>
                <meta property="twitter:description"
                      content="Guess the artist of the day based on their songs and share your score with your friends. Can you find it before running out of guesses?"/>
                <meta property="twitter:image" content="https://artistle.jbonet.xyz/images/artistle_og_1200x630.png"/>
                <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
                <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/manifest.json"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
                <meta name="theme-color" content="#ffffff"/>
            </Head>

            <Component {...pageProps} />
        </>
    )
}

export default ArtistleApp
